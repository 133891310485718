import img1 from '../images/review1.png'
import img2 from '../images/review2.png'
import img3 from '../images/review3.png'
import img4 from '../images/review4.png'
import img5 from '../images/photo_2024-02-05_19-21-11.jpg'


export const firstList = [
  "Все виды отделочных работ под ключ ",
  "⁠Электромонтажные работы",
  "Сантехнические работы",
  "Натяжка навесных потолков",
  "Штукатурка стен ",
  "Шпаклевка стен ",
  "⁠Покраска,поклейка обоев, декоративная штукатурка",
  "⁠Укладка плитки пола",
  "Укладка плитки стен",
  "Установка дверей межкомнатных , входных и противопожарных. Профессиональная Бригада мастеров.",
];

export const secondList = [
    '⁠Работы в оговорённые сроки',
    '⁠Помощь в поставке и выборе материала',
    '⁠Покупая материалы у нас вы получаете скидку на работы и материалы',
    '⁠Работаем напрямую с поставщиками',
    '⁠Работаем с физическими и юридическими лицами по договору',
    'Помощь в составлении документации',
    'Смета в подарок'
]

export const fasadList = [
    'Мокрый фасад',
    'НФС (навесные фасадные системы) под керамогранит',
    '⁠АКП (алюминиевые Композитные панели)',
    '⁠бетонная ,клинкерная плитка',
    '⁠фиброцементные панели',
    '⁠металические кассеты',
    '⁠стекло фибро бетон',
    '⁠обшивка металическим сайдингом',
    '⁠натуральный камень'
]

export const krovelList = [
    'рулонные и мастичные – используются для плоских крыш, наиболее простой в укладке вариант;',
    'листовые – подходят для крыш больших размеров, позволят ускорить кровельные работы;',
    ' мелкоштучные – самые долговечные, подходят для крыш со сложной архитектурой, отдельные элементы такой кровли при необходимости легко заменить.',

]

export const sliderImg = [img1,img2,img3,img4,img5]





