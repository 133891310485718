import React from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import style from "./Slider.module.css";
import { sliderImg } from "../../constants/listText";

export default function Slider() {
  return (
    <Swiper
      modules={[Navigation, Pagination]}
      className={style.slider}
      spaceBetween={2}
      navigation
      breakpoints={{
        320: {
          slidesPerView: 1,
        },
        1920: {
          slidesPerView: 3.1,
        },
        850: {
          slidesPerView: 3.1,
        },
      }}
    >
      {sliderImg.map((img,index) => (
        <SwiperSlide key={index} className={style.slide}>
          <div className={style.img__container}>
            <img className={style.img} src={img} alt="" />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
