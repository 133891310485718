import style from '../SecondBlock.module.css'

export default function TableCell({img, title, text}) {
  return (
    <li className={style.table__cell}>
      <img className={style.img} src={img} alt="" />
      <h3 className={style.table__title}>{title}</h3>
      <p className={style.table__text}>
        {text}
      </p>
    </li>
  );
}
