import style from "./FourthBlock.module.css";
import List from "../SecondBlock/List/List";
import { krovelList } from "../../constants/listText";
import img from "../../images/krovel_img.png";
import Button from "../Button/Button";
import Module from "../Module/Module";

export default function FourthBlock({ onClick }) {
  return (
    <Module id="section4" backgroundColor="#5C3EDE">
      <div className={style.container}>
        <div className={style.img__container}>
          <img className={style.img} src={img} alt="Изображение кровли" />
        </div>
        <div className={style.list__container}>
          <h2 className={style.title}>Кровельные работы</h2>
          <List listStyle={style.list__item} list={krovelList} />
          <div className={style.button__container}>
            <Button
              onClick={onClick}
              className={style.button}
              margin={0}
            >
               <p className={style.button__text}>Рассчитать стоимость</p>
            </Button>
          </div>
        </div>
      </div>
    </Module>
  );
}
