import style from "./FirstBlock.module.css";
import img from "../../images/first_block.png";
import Button from "../Button/Button";
import TableCell from "./TableCell/TableCell";
import Module from "../Module/Module";

export default function FirstBlock({ onClick }) {
  return (
    <Module backgroundColor="#fff">
      <div className={style.container}>
        <div className={style.main__container}>
          <div className={style.img__container}>
            <img className={style.image} src={img} alt="" />
          </div>
          <div className={style.text__container}>
            <h2 className={style.title}>
              Ремонт и отделка{" "}
              <span style={{ color: "#3416B6" }} className={style.title}>
                помещений
              </span>{" "}
              под ключ
            </h2>
            <p className={style.text}>
              Профессиональный ремонт квартир и домов под ключ. Качественная
              отделка от жилья до коммерческих объектов. Идеальное исполнение
              для вашего пространства!
            </p>
            <div className={style.button__container}>
              <Button
                margin={0}
                color="#5C3EDE"
                textColor="#fff"
              >
                <p className={style.button__text}>
                  Рассчитать стоимость
                </p>
              </Button>
            </div>
          </div>
        </div>
        <div id="section1" className={style.second_container}>
          <h2 className={style.title}>
            Прозрачный и понятный{" "}
            <span style={{ color: "#3416B6" }} className={style.title}>
              процесс
            </span>{" "}
            работы
          </h2>
          <ul className={style.table}>
            <TableCell
              text="Вы оставляете заявку на сайте или звоните нам по телефону"
              title="Заявка"
              number="1"
            />
            <TableCell
              text="В удобное для вас время специалист приезжает на объект для замера и просчета"
              title="Выезд на объект"
              number="2"
            />
            <TableCell
              text="Перед началом работ вы знаете точную стоимость и сроки всех услуг"
              title="Согласование сметы"
              number="3"
            />
            <TableCell
              text="Вы заключаете официальный договор, где прописаны все наши обязательства"
              title="Заключение договора"
              number="4"
            />
            <TableCell
              text="Мы выполняем работы, присылаем отчеты, а вы частями оплачиваете каждый принятый этап"
              title="Процесс работы"
              number="5"
            />
            <TableCell
              text="Все работы проверяет отдел по контролю качества и только после этого вы принимаете объект"
              title="Довольный клиент"
              number="6"
            />
          </ul>
        </div>
      </div>
    </Module>
  );
}
