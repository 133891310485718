import style from "../FirstBlock.module.css";

export default function TableCell({ number, title, text }) {
  return (
    <li className={style.table__item}>
      <h3 className={style.table__count}>{number}</h3>
      <div className={style.table__cell}>
        <p style={{ fontSize: "25px" }} className={style.table__text}>
          {title}
        </p>
        <p style={{ maxWidth: `273px` }} className={style.table__text}>
          {text}
        </p>
      </div>
    </li>
  );
}
