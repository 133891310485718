import { useModal } from "../../contexts/PopupProvider";
import style from "./Button.module.css";
import Loader from "react-bootstrap-button-loader";

export default function Button({
  isLoading,
  color,
  children,
  margin,
  onClick,
}) {
  const { handleSetModal } = useModal();

  return (
    <Loader
      onClick={onClick ? onClick : handleSetModal}
      style={{
        cursor: "pointer",
        backgroundColor: `${color}`,
        margin: `${margin}`,
      }}
      className={style.button}
      loading={isLoading}
      spinColor="#000"
    >
      {!isLoading && children}
    </Loader>
  );
}
