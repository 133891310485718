import img from "../../images/image 144.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import style from "./Slider2.module.css";

export default function Slider2() {
  return (
    <>
      <Swiper
        spaceBetween={0}
        slidesPerView={2}
        speed={2000}
        autoplay={{
          delay: 0,
        }}
        modules={[Autoplay]}
        className={style.slider}
        loop={true}
      >
        <SwiperSlide>
          <div className={style.img_container}>
            <img className={style.img} src={img} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={style.img_container}>
            <img className={style.img} src={img} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={style.img_container}>
            <img className={style.img} src={img} alt="" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={style.img_container}>
            <img className={style.img} src={img} alt="" />
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
