import Button from "../Button/Button";
import style from "./Form.module.css";
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useState } from "react";

export default function Form() {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    shouldUnregister: false,
  });

  function onSubmit(data) {
    setIsLoading(true);
    const resData = {
      user : data.userName,
      phone : data.userPhone
    }
    axios
      .post("mail.php", resData)
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
    reset({
      agreement: "",
      userPhone: "",
      userName: "",
    });
  }

  return (
    <div
      style={{ justifyContent: "flex-start" }}
      className={style.main_container}
    >
      <div className={style.popup__container}>
        <h2 className={style.title}>
          <span style={{ color: "#5C3EDE" }}>Ремонт</span> может быть разный
        </h2>
        <p style={{ maxWidth: "1050px" }} className={style.text}>
          Если вам нужна консультация, оставьте заявку — наши специалисты
          свяжутся с вами и ответят на любые вопросы
        </p>
        <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={style.label_container}>
            <input
              {...register("userName", {
                required: "Введите имя...",
                minLength: {
                  value: 4,
                  message: "Минимум 4 символа...",
                },
              })}
              className={style.input}
              type="text"
              placeholder="Ваше имя..."
            />
            <label className={style.error__span}>
              {errors?.userName &&
                (errors?.userName?.message || "Неизвестная ошибка")}
            </label>
          </div>
          <div className={style.label_container}>
            <InputMask
              {...register("userPhone", {
                required: "Введите телефон...",
                pattern: /^([+]?[0-9\s-()]{3,25})*$/i,
                minLength: {
                  value: 11,
                  message: "Неверный номер телефона...",
                },
              })}
              mask={"+7 999 999 99 99"}
              maskChar={""}
              className={style.input}
              type="tel"
              placeholder="Ваш телефон..."
            />
            <label className={style.error__span}>
              {errors?.userPhone &&
                (errors?.userPhone?.message || "Неизвестная ошибка")}
            </label>
          </div>
          <div className={style.button__container}>
            <Button
              isLoading={isLoading}
              onClick={handleSubmit(onSubmit)}
              margin={0}
              color="#5C3EDE"
            >
              <p className={style.button__text}>Рассчитать стоимость</p>
            </Button>
          </div>
        </form>
        <div className={style.checkbox__container}>
          <input
            {...register("agreement", {
              required: "Вы не приняли условия соглашения...",
            })}
            className={style.checkbox}
            id="checkbox1"
            type="checkbox"
          />
          <label htmlFor="checkbox1" className={style.label}>
            <p className={style.text__secondary}>
              Принимаю условия Пользовательского соглашения и даю Согласие
              на обработку персональных данных в соответствии с Политикой
              конфиденциальности
            </p>
          </label>
          <label className={style.error__span}>
            {errors?.agreement &&
              (errors?.agreement?.message || "Неизвестная ошибка")}
          </label>
        </div>
      </div>
    </div>
  );
}
