import { createContext, useContext, useState } from "react";

const ModalContext = createContext();

export function ModalProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleSetModal() {
    setIsOpen(!isOpen)
  }

  return (
    <ModalContext.Provider value={{ isOpen, handleSetModal }}>
      {children}
    </ModalContext.Provider>
  );
}

export const useModal = () => {
  return useContext(ModalContext)
};
