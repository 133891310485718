import style from "./SecondBlock.module.css";
import img1 from "../../images/table1.svg";
import img2 from "../../images/table2.svg";
import img3 from "../../images/table3.svg";

import TableCell from "./TableCell/TableCell";
import List from "./List/List";
import { firstList } from "../../constants/listText";
import { secondList } from "../../constants/listText";
import Module from "../Module/Module";
import Form from "../Form/Form";

export default function SecondBlock() {
  return (
    <Module backgroundColor="#5C3EDE">
      <div className={style.main__container}>
        <h2 id="section2" className={style.title}>Преимущества нашей компании</h2>
        <ul className={style.table}>
          <TableCell
            img={img1}
            text="Мы берём минимальную предоплату. Вы платите за каждый этап работ после подписания акта."
            title="Минимальная предоплата"
          />
          <TableCell
            img={img2}
            text="Прозрачный бюджет. Финальная смета фиксируется в согласованных актах."
            title="Стоимость ремонта не меняется"
          />
          <TableCell
            img={img3}
            text="Качество работ для нас в приоритете. Даём гарантию на все виды работ."
            title="За нами не переделывают"
          />
        </ul>
        <Form />
        <div className={style.secondary__container}>
          <h3 id='section3'  className={style.secondary__title}>Что мы делаем? Все!</h3>
          <div className={style.list__container}>
            <List listStyle={style.list__item} list={firstList} width="786px" />
            <List listStyle={style.list__item} list={secondList} />
          </div>
        </div>
      </div>
    </Module>
  );
}
