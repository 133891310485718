import "./App.css";
import FirstBlock from "./components/FirstBlock/FirstBlock";
import FourthBlock from "./components/FourthBlock/FourthBlock";
import Header from "./components/Header/Header";
import SecondBlock from "./components/SecondBlock/SecondBlock";
import ThirdBlock from "./components/ThirdBlock/ThirdBlock";
import FifthBlock from "./components/FifthBlock/FifthBlock";
import Popup from "./components/Popup/Popup";
import Footer from "./components/Footer/Footer";
import SixthBlock from "./components/SixthBlock/SixthBlock";
import { ModalProvider } from "./contexts/PopupProvider";

function App() {
  return (
    <ModalProvider>
      <div className="App">
        <Popup />
        <Header />
        <FirstBlock  />
        <SecondBlock  />
        <ThirdBlock />
        <FourthBlock  />
        <SixthBlock />
        <FifthBlock />
        <Footer />
      </div>
    </ModalProvider>
  );
}

export default App;
