import style from "./Header.module.css";
import icon from "../../images/ic_phone.svg";
import Button from "../Button/Button";
import { Link } from "react-scroll";
import BurgerMenu from "../BurgerMenu/BurgerMenu";
import menu_icon from "../../images/burger_icon.svg";
import { useState } from "react";

export default function Header() {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const handleOpenMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  function onOpenPopup() {
    setIsOpenMenu(!isOpenMenu);
  }

  return (
    <header className={style.container}>
      <p className={style.title}>ООО "СТРОЙПОЛИМЕР"</p>
      <div className={style.menu}>
        <nav className={style.nav__container}>
          <Link
            to="section1"
            smooth={true}
            duration={500}
            spy={true}
            className={style.text}
          >
            Процесс работы
          </Link>
          <Link
            to="section2"
            onCl
            smooth={true}
            duration={500}
            spy={true}
            className={style.text}
          >
            Преимущества
          </Link>
          <Link
            to="section3"
            smooth={true}
            duration={500}
            spy={true}
            className={style.text}
          >
            Услуги
          </Link>
          <Link
            to="footer"
            smooth={true}
            duration={500}
            spy={true}
            style={{ textDecoration: "none", cursor: "pointer" }}
            className={style.text}
          >
            Контакты
          </Link>
        </nav>
        <div className={style.phone_container}>
          <a href="tel: +79308444446" className={style.link}>
            <img className={style.icon} src={icon} alt="phone icon" />
            +7 930 844 44 46
          </a>
        </div>
        <div className={style.button__container}>
          <Button color="#fff">
            <p className={style.button__text}>Рассчитать стоимость</p>
          </Button>
        </div>
      </div>
      <div>
        <div className={style.icon__container}>
          <img
            style={{ cursor: "pointer" }}
            onClick={handleOpenMenu}
            className={style.menu__icon}
            src={menu_icon}
            alt="Иконка меню"
          />
        </div>
        <BurgerMenu
          onClickPopup={onOpenPopup}
          isOpen={isOpenMenu}
          onClick={handleOpenMenu}
        />
      </div>
    </header>
  );
}
