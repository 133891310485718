import style from "./Module.module.css";

export default function Module({ children, backgroundColor, id }) {
  return (
    <section
      id={id}
      style={{ backgroundColor: `${backgroundColor}` }}
      className={style.container}
    >
      {children}
    </section>
  );
}
