import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import icon from "../../images/icons8-close.svg";
import img from "../../images/popup_image.png";
import Button from "../Button/Button";
import style from "./Popup.module.css";
import { useModal } from "../../contexts/PopupProvider";

export default function Popup() {
  const {isOpen, handleSetModal} = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  function onSubmit(data) {
    setIsLoading(true);
    const resData = {
      user: data.userName,
      phone: data.userPhone,
    };
    axios
      .post("mail.php", resData)
      .then(() => {
        setIsLoading(false);
        handleClose();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
    reset({
      agreement: "",
      userPhone: "",
      userName: "",
    });
  }

  const handleClick = (event) => {
    if (event.target.classList.contains(style.overlay__open)) {
      reset({
        agreement: "",
        userPhone: "",
        userName: "",
      });

      handleSetModal();
    }
  };

  function handleClose() {
    handleSetModal();
    reset({
      agreement: "",
      userPhone: "",
      userName: "",
    });
  }

  return (
    <div
      onClick={handleClick}
      className={isOpen ? style.overlay__open : style.overlay}
    >
      <div className={style.container}>
        <div className={style.main_container}>
          <div className={style.popup__container}>
            <img
              onClick={handleClose}
              className={style.close}
              src={icon}
              alt="Иконка закрытия"
            />
            <h2 className={style.title}>
              <span style={{ color: "#5C3EDE" }}>Ремонт</span> может быть разный
            </h2>
            <p className={style.text}>
              Если вам нужна консультация, оставьте заявку — наши специалисты
              свяжутся с вами и ответят на любые вопросы
            </p>
            <form
              action="/mail.php"
              className={style.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className={style.input__container}>
                <input
                  {...register("userName", {
                    required: "Введите имя...",
                    minLength: {
                      value: 4,
                      message: "Минимум 4 символа...",
                    },
                  })}
                  className={style.input}
                  type="text"
                  placeholder="Ваше имя..."
                />
                <label className={style.error__span}>
                  {errors?.userName &&
                    (errors?.userName?.message || "Неизвестная ошибка")}
                </label>
              </div>
              <div className={style.input__container}>
                <InputMask
                  {...register("userPhone", {
                    required: "Введите телефон...",
                    pattern: /^([+]?[0-9\s-()]{3,25})*$/i,
                    min: {
                      value: 11,
                      message: "Неверный номер телефона...",
                    },
                  })}
                  mask={"+7 (999) 999 99 99"}
                  maskChar={""}
                  className={style.input}
                  type="tel"
                  placeholder="Ваш телефон..."
                />
                <label className={style.error__span}>
                  {errors?.userPhone &&
                    (errors?.userPhone?.message ||
                      "Необходимо ввести номер телефона...")}
                </label>
              </div>
              <div className={style.button__container}>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  isLoading={isLoading}
                  margin={0}
                  color="#5C3EDE"
                >
                  <p className={style.button__text}>Рассчитать стоимость</p>
                </Button>
              </div>
            </form>
            <div className={style.checkbox__container}>
              <input
                {...register("agreement", {
                  required: "Вы не приняли условия соглашения...",
                })}
                className={style.checkbox}
                id="checkbox"
                type="checkbox"
              />
              <label for="checkbox" className={style.label}>
                <p className={style.text__secondary}>
                  Принимаю условия Пользовательского соглашения и даю Согласие
                  на обработку персональных данных в соответствии с Политикой
                  конфиденциальности
                </p>
              </label>
              <label className={style.error__span_last}>
                {errors?.agreement &&
                  (errors?.agreement?.message || "Неизвестная ошибка")}
              </label>
            </div>
          </div>
          <div className={style.image__container}>
            <img className={style.image} src={img} alt="popup_image" />
          </div>
        </div>
      </div>
    </div>
  );
}
