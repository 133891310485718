import React from "react";
import { Link } from "react-scroll";
import style from "./BurgerMenu.module.css";
import Button from "../Button/Button";
import icon from "../../images/close_icon_white.svg";
import { useModal } from "../../contexts/PopupProvider";

export default function BurgerMenu({ isOpen = false, onClick }) {
  const { handleSetModal } = useModal();

  const handleClick = (event) => {
    if (event.target.classList.contains(style.overlay__open)) {
      onClick();
    }
  };

  function closeModal() {
    onClick();
    handleSetModal();
  }

  return (
    <div
      onClick={handleClick}
      className={isOpen ? style.overlay__open : style.overlay}
    >
      <div
        className={
          isOpen ? style.burger__container_open : style.burger__container
        }
      >
        <img
          onClick={onClick}
          className={style.icon}
          src={icon}
          alt="Иконка закрытия"
        />
        <nav className={style.burger__menu}>
          <Link
            onClick={onClick}
            to="section1"
            smooth={true}
            duration={500}
            spy={true}
            className={style.text}
          >
            Процесс работы
          </Link>
          <Link
            to="section2"
            onClick={onClick}
            smooth={true}
            duration={500}
            spy={true}
            className={style.text}
          >
            Преимущества
          </Link>
          <Link
            to="section3"
            onClick={onClick}
            smooth={true}
            duration={500}
            spy={true}
            className={style.text}
          >
            Услуги
          </Link>
          <Link
            to="footer"
            onClick={onClick}
            smooth={true}
            duration={500}
            spy={true}
            style={{ textDecoration: "none", cursor: "pointer" }}
            className={style.text}
          >
            Контакты
          </Link>
          <a
            href="tel: +79308444446"
            style={{ textDecoration: "none", cursor: "pointer" }}
            className={style.text}
          >
            +7 930 844 44 46
          </a>
          <div className={style.button__container}>
            <Button
              onClick={closeModal}
              fontSize="12px"
              textColor="#000"
              height="100%"
              width="100%"
            >
              <p className={style.button__text}>Рассчитать стоимость</p>
            </Button>
          </div>
        </nav>
      </div>
    </div>
  );
}
