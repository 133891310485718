import style from './ThirdBlock.module.css'
import List from '../SecondBlock/List/List'
import { fasadList } from '../../constants/listText'
import img from '../../images/fasad_img.png'
import Module from '../Module/Module'
 
export default function ThirdBlock() {
  return (
    <Module backgroundColor="#fff">
    <div className={style.container}>
        <div className={style.list__container}>
            <h2  className={style.title}>Фасады</h2>
            <List listStyle={style.list__item} list={fasadList}/>
        </div>
        <div className={style.img__container}>
        <img className={style.img} src={img} alt="Изображение фасада" />
        </div>
    </div>
    </Module>
  )
}
