import style from './List.module.css'

export default function List({width,list,listStyle}) {
    return (
      <ul style={{maxWidth:`${width}`}} className={style.list}>
        {list.map((item,index) => (
          <li key={index} className={listStyle}>{item}</li>
        ))}
      </ul>
    );
  }