import Button from "../Button/Button";
import style from "./Footer.module.css";
import { Link } from "react-scroll";

export default function Footer() {
  return (
    <section id="footer" className={style.footer}>
      <div className={style.container}>
        <h2 className={style.title}>ООО “СТРОЙПОЛИМЕР”</h2>
        <p className={style.text}>
          248000, Калужская область, г.Калуга, ул. Гагарина, дом 1, стр.52,
          оф.438
        </p>
        <div className={style.link__container}>
          <a className={style.sublink} href="tel: +79308444446">
            +7 930 844 44 46
          </a>
          <a className={style.sublink} href="mailto: info@companyremont.ru">
            info@companyremont.ru
          </a>
        </div>

        <div className={style.button__container}>
          <Button margin="0px" color="#fff">
            <p className={style.button__text}>Рассчитать стоимость</p>
          </Button>
        </div>
      </div>
      <nav className={style.nav__container}>
        <Link
          to="section1"
          smooth={true}
          duration={500}
          spy={true}
          className={style.link}
        >
          Процесс работы
        </Link>
        <Link
          to="section2"
          smooth={true}
          duration={500}
          spy={true}
          className={style.link}
        >
          Преимущества
        </Link>
        <Link
          to="section3"
          smooth={true}
          duration={500}
          spy={true}
          className={style.link}
        >
          Услуги
        </Link>
        <a
          href="tel: +79308444446"
          style={{ textDecoration: "none", cursor: "pointer" }}
          className={style.link}
        >
          Контакты
        </a>
      </nav>
    </section>
  );
}
