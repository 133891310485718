import Module from '../Module/Module'
import Slider from '../Slider/Slider'
import style from './FifthBlock.module.css'


export default function FifthBlock() {
    
  return (
    <Module id='section5' backgroundColor='#fff'>
    <div>
        <h2 className={style.title}>Отзывы наших клиентов</h2>
        <Slider/>
    </div>
    </Module>
  )
}
