import Slider2 from "../Slider2/Slider2";
import style from "./SixthBlock.module.css";
import gz from "../../images/logo_gazprom.png";
import gzK from "../../images/logo_gazpromK.png";
import pf from "../../images/logo_pf.png";

export default function SixthBlock() {
  return (
    <section className={style.section}>
      <div className={style.container}>
        <h2 className={style.title}>
          Одно из <span style={{ color: "#5C3EDE" }}>преимуществ</span> -
          собственная база поставщиков.
        </h2>
        <Slider2 />
      </div>
      <div className={style.subtitle__container}>
        <h2 className={style.subtitle}>
          Мы являемся подрядчиками компаний Газпром, МежрегионГаз Калуга,
          Пенсионный фонд России{" "}
        </h2>
      </div>
      <div className={style.logo__container}>
        <div className={style.logo}>
          <img className={style.logo__img} src={gz} alt="Логотип газпрома" />
        </div>
        <div className={style.logo}>
          <img className={style.logo__img} src={gzK} alt="Логотип газпрома Калуга" />
        </div>
        <div className={style.logo}>
          <img className={style.logo__img} src={pf} alt="Логотип пенсионного фонда" />
        </div>
      </div>
    </section>
  );
}
